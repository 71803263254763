import ChartConfig from "Constants/ChartConfig";
export const marketCapDetails = [
	{
		sr_no: "01",
		currency: "Bitcoin",
		market_cap: "$172,12783",
		price: "$12983",
		volume: "$31,440,000",
		change: "+ 10.86%",
		more: "More",
		visible: true
	},
	{
		sr_no: "02",
		currency: "Bitcoin",
		market_cap: "$172,12783",
		price: "$12983",
		volume: "$31,440,000",
		change: "+ 10.86%",
		more: "More",
		visible: false
	},
	{
		sr_no: "03",
		currency: "Bitcoin",
		market_cap: "$172,12783",
		price: "$12983",
		volume: "$31,440,000",
		change: "+ 10.86%",
		more: "More",
		visible: false
	},
	{
		sr_no: "04",
		currency: "Bitcoin",
		market_cap: "$172,12783",
		price: "$12983",
		volume: "$31,440,000",
		change: "+ 10.86%",
		more: "More",
		visible: false
	},
	{
		sr_no: "05",
		currency: "Bitcoin",
		market_cap: "$172,12783",
		price: "$12983",
		volume: "$31,440,000",
		change: "+ 10.86%",
		more: "More",
		visible: false
	},
	{
		sr_no: "06",
		currency: "Bitcoin",
		market_cap: "$172,12783",
		price: "$12983",
		volume: "$31,440,000",
		change: "+ 10.86%",
		more: "More",
		visible: false
	},
	{
		sr_no: "07",
		currency: "Bitcoin",
		market_cap: "$172,12783",
		price: "$12983",
		volume: "$31,440,000",
		change: "+ 10.86%",
		more: "More",
		visible: false
	},
	{
		sr_no: "08",
		currency: "Bitcoin",
		market_cap: "$172,12783",
		price: "$12983",
		volume: "$31,440,000",
		change: "+ 10.86%",
		more: "More",
		visible: false
	}
]

//Exchange Statistics
export const exchangeStatistic = [
	{
		icon1: "cc BTC text-primary font-3x",
		icon2: "cc ETH text-success font-3x",
		icon1_name: "Bitcoin",
		icon2_name: "Ethereum",
		price: "0.00004356",
		volume: "8.6367290",
		percentage: "86",
		chartLabel: ['9', '10', '11', '12', '13', '14', '15', '16'],
		data: [30, 5, 26, 10, 30, 5, 26, 10],

	},
	{
		icon1: "cc ETH text-success font-3x",
		icon2: "cc LTC text-danger font-3x",
		icon1_name: "Ethereum",
		icon2_name: "Litecoin",
		price: "0.00004356",
		volume: "8.6367290",
		percentage: "89",
		chartLabel: ['9', '10', '11', '12', '13', '14', '15', '16'],
		data: [1, 26, 8, 22, 26, 8, 22, 1],

	},
	{
		icon1: "cc LTC text-danger font-3x",
		icon2: "cc BTC text-primary font-3x",
		icon1_name: "Litecoin",
		icon2_name: "Bitcoin",
		price: "0.00004356",
		volume: "8.6367290",
		percentage: "84",
		chartLabel: ['9', '10', '11', '12', '13', '14', '15', '16'],
		data: [10, 26, 5, 30, 10, 26, 5, 30],

	}
]

export const safeTradeContent = [
	{
		icon: "cc BTC text-primary",
		name: "Bitcoin",
		color: "primary",
		duration: "last 4 days",
		market_cap: "2,056,894.05",
		chartLabel: ["A", "B", "C", "D", "E"],
		data: [1, 26, 8, 22, 1],
		percentage: '2.3%',
		percentageColor: 'text-success',
		percentageIcon: 'zmdi zmdi-long-arrow-up',
		chartBorderColor: ChartConfig.color.primary
	},
	{
		icon: "cc ETH text-success",
		name: "Ethereum",
		color: "success",
		duration: "last 4 days",
		market_cap: "3,056,894.05",
		chartLabel: ["A", "B", "C", "D", "E"],
		data: [29, 5, 26, 10, 21],
		percentage: '2.3%',
		percentageColor: 'text-success',
		percentageIcon: 'zmdi zmdi-long-arrow-up',
		chartBorderColor: '#43a047',

	},
	{
		icon: "cc LTC text-danger",
		name: "Litecoin",
		color: "warn",
		duration: "last 4 days",
		market_cap: "4,06,894.05",
		chartLabel: ["A", "B", "C", "D", "E"],
		data: [1, 26, 8, 22, 1],
		percentage: '2.3%',
		percentageColor: 'text-success',
		percentageIcon: 'zmdi zmdi-long-arrow-up',
		chartBorderColor: '#e53935',

	},
	{
		icon: "cc ZEC-alt text-info",
		name: "Zcash",
		color: "accent",
		duration: "last 4 days",
		market_cap: "2,076,94.05",
		chartLabel: ["A", "B", "C", "D", "E"],
		data: [29, 5, 26, 10, 21],
		percentage: '2.3%',
		percentageColor: 'text-danger',
		percentageIcon: 'zmdi zmdi-long-arrow-down',
		chartBorderColor: '#0097a7',

	}
]

export const coinsList = [
	{
		"serial_number": "1",
		"desktop_name": "Bitcoin",
		"mobile_name": "BTC",
		"price": "Ƀ 1.00",
		"volume": "Ƀ 97.78 k",
		"total_volume": "Ƀ 452.93 k",
		"change": 0.00,
		"tag": "BTC",
		"status": "Leading",
		"market_cap": "Ƀ 17.58 M",
		"circulating_supply": "17,583,712 BTC",
	},
	{
		"serial_number": "2",
		"desktop_name": "Bitcoin",
		"mobile_name": "BTC",
		"price": "Ξ 27.64",
		"volume": "-",
		"total_volume": "Ξ 13.37 M",
		"change": 1.49,
		"tag": "ETH",
		"status": "Leading",
		"market_cap": "Ξ 513.51 M",
		"circulating_supply": "17,583,712 ETH"
	},
	{
		"serial_number": "3",
		"desktop_name": "Bitcoin",
		"mobile_name": "BTC",
		"price": "€ 119.97",
		"volume": "€ 21.50 M",
		"total_volume": "€ 1.55 B",
		"change": -0.08,
		"tag": "EUR",
		"status": "Trailing",
		"market_cap": "€ 60.37 B",
		"circulating_supply": "17,583,712 EUR"
	},
	{
		"serial_number": "4",
		"desktop_name": "Ethereum",
		"mobile_name": "ETH",
		"price": "Ƀ 0.03572",
		"volume": "Ƀ 126.65 k",
		"total_volume": "Ƀ 452.93 k",
		"change": -0.03,
		"tag": "BTC",
		"status": "Leading",
		"market_cap": "Ƀ 17.58 M",
		"circulating_supply": "17,583,712 BTC"
	},
	{
		"serial_number": "5",
		"desktop_name": "Ethereum",
		"mobile_name": "ETH",
		"price": "Ξ 1.00",
		"volume": "Ξ 11.19 M",
		"total_volume": "Ξ 13.37 M",
		"change": 2.16,
		"tag": "ETH",
		"status": "Trailing",
		"market_cap": "Ξ 513.51 M",
		"circulating_supply": "17,583,712 ETH"
	},
	{
		"serial_number": "6",
		"desktop_name": "Ethereum",
		"mobile_name": "ETH",
		"price": "€ 120.41",
		"volume": "€ 21.43 M",
		"total_volume": "€ 1.55 B",
		"change": 0.41,
		"market_cap": "€ 60.37 B",
		"tag": "EUR",
		"status": "Trailing",
		"circulating_supply": "17,583,712 EUR"
	},
	{
		"serial_number": "7",
		"desktop_name": "EOS",
		"mobile_name": "EOS",
		"price": "Ƀ 0.0009280",
		"volume": "Ƀ 58.97 k",
		"total_volume": "Ƀ 452.93 k",
		"change": 1.94,
		"tag": "BTC",
		"status": "Trailing",
		"market_cap": "Ƀ 17.58 M",
		"circulating_supply": "17,583,712 BTC"
	},
	{
		"serial_number": "8",
		"desktop_name": "EOS",
		"mobile_name": "EOS",
		"price": "Ξ 0.02596",
		"volume": "Ξ 1.85 M",
		"total_volume": "Ξ 13.37 M",
		"change": 2.04,
		"tag": "ETH",
		"status": "Trailing",
		"market_cap": "Ξ 513.51 M",
		"circulating_supply": "17,583,712 ETH"
	},
	{
		"serial_number": "9",
		"desktop_name": "EOS",
		"mobile_name": "EOS",
		"price": "€ 3.16",
		"volume": "€ 2.03 M",
		"total_volume": "€ 1.55 B",
		"change": 2.27,
		"tag": "EUR",
		"status": "Leading",
		"market_cap": "€ 60.37 B",
		"circulating_supply": "17,583,712 EUR"
	},
	{
		"serial_number": "10",
		"desktop_name": "Litecoin",
		"mobile_name": "LTC",
		"price": "Ƀ 0.01160",
		"volume": "Ƀ 39.63 k",
		"total_volume": "Ƀ 452.93 k",
		"change": 0.77,
		"tag": "BTC",
		"status": "Leading",
		"market_cap": "Ƀ 17.58 M",
		"circulating_supply": "17,583,712 BTC"
	},
	{
		"serial_number": "11",
		"desktop_name": "Litecoin",
		"mobile_name": "LTC",
		"price": "Ξ 0.3322",
		"volume": "Ξ 12.03 k",
		"total_volume": "Ξ 13.37 M",
		"change": 0.94,
		"tag": "ETH",
		"status": "Trailing",
		"market_cap": "Ξ 513.51 M",
		"circulating_supply": "17,583,712 ETH"
	},
	{
		"serial_number": "12",
		"desktop_name": "Litecoin",
		"mobile_name": "LTC",
		"price": "€ 39.77",
		"volume": "€ 2.20 M",
		"total_volume": "€ 1.55 B",
		"change": 1.02,
		"tag": "EUR",
		"status": "Leading",
		"market_cap": "€ 60.37 B",
		"circulating_supply": "17,583,712 EUR"
	},
	{
		"serial_number": "13",
		"desktop_name": "XRP",
		"mobile_name": "XRP",
		"price": "Ƀ 0.00008144",
		"volume": "Ƀ 189.39 k",
		"total_volume": "Ƀ 452.93 k",
		"change": -0.78,
		"tag": "BTC",
		"status": "Trailing",
		"market_cap": "Ƀ 17.58 M",
		"circulating_supply": "17,583,712 BTC"
	},
	{
		"serial_number": "14",
		"desktop_name": "XRP",
		"mobile_name": "XRP",
		"price": "Ξ 0.002286",
		"volume": "Ξ 139.58 k",
		"total_volume": "Ξ 13.37 M",
		"change": -0.70,
		"market_cap": "Ξ 513.51 M",
		"tag": "ETH",
		"status": "Leading",
		"circulating_supply": "17,583,712 ETH"
	},
	{
		"serial_number": "15",
		"desktop_name": "XRP",
		"mobile_name": "XRP",
		"price": "€ 0.2729",
		"volume": "€ 5.26 M",
		"tag": "EUR",
		"status": "Leading",
		"total_volume": "€ 1.55 B",
		"change": -0.87,
		"market_cap": "€ 60.37 B",
		"circulating_supply": "17,583,712 EUR"
	},
	{
		"serial_number": "16",
		"desktop_name": "Bitcoin Cash",
		"mobile_name": "BCH",
		"price": "Ƀ 0.03841",
		"volume": "Ƀ 10.27 k",
		"total_volume": "Ƀ 452.93 k",
		"change": 0.00,
		"tag": "BTC",
		"status": "Trailing",
		"market_cap": "Ƀ 17.58 M",
		"circulating_supply": "17,583,712 BTC"
	},
	{
		"serial_number": "17",
		"desktop_name": "Bitcoin Cash",
		"mobile_name": "BCH",
		"price": "Ξ 1.01",
		"volume": "Ξ 225.53 k",
		"total_volume": "Ξ 13.37 M",
		"change": 4.11,
		"tag": "ETH",
		"status": "Leading",
		"market_cap": "Ξ 513.51 M",
		"circulating_supply": "17,583,712 ETH"
	},
	{
		"serial_number": "18",
		"desktop_name": "Bitcoin Cash",
		"mobile_name": "BCH",
		"price": "€ 0.2728",
		"volume": "€ 5.26 M",
		"total_volume": "€ 1.55 B",
		"change": -0.91,
		"tag": "EUR",
		"status": "Trailing",
		"market_cap": "€ 60.37 B",
		"circulating_supply": "17,583,712 EUR"
	},
	{
		"serial_number": "19",
		"desktop_name": "ZCash",
		"mobile_name": "ZEC",
		"price": "Ƀ 0.01334",
		"volume": "Ƀ 9,188.98 K",
		"total_volume": "Ƀ 452.93 k",
		"change": -2.20,
		"tag": "BTC",
		"status": "Trailing",
		"market_cap": "Ƀ 17.58 M",
		"circulating_supply": "17,583,712 BTC"
	},
	{
		"serial_number": "20",
		"desktop_name": "ZCash",
		"mobile_name": "ZEC",
		"price": "Ξ 0.3740",
		"volume": "Ξ 498.19 k",
		"total_volume": "Ξ 13.37 M",
		"change": -1.84,
		"tag": "ETH",
		"status": "Leading",
		"market_cap": "Ξ 513.51 M",
		"circulating_supply": "17,583,712 ETH"
	},
	{
		"serial_number": "21",
		"desktop_name": "ZCash",
		"mobile_name": "ZEC",
		"price": "€ 45.69",
		"tag": "EUR",
		"status": "Leading",
		"volume": "€ 89.09 k",
		"total_volume": "€ 1.55 B",
		"change": -2.25,
		"market_cap": "€ 60.37 B",
		"circulating_supply": "17,583,712 EUR"
	}
]